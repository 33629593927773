<template>
  <div class="homePage">
    <div class="homePage1">
      <el-container style="width: 100%; height: 100%">
        <el-aside class="aside" width="250px">
          <el-header class="aside-header">
            <div class="aside-header1">
              <img src="../../assets/庆快校园.jpg" alt="" />
            </div>
            <div class="title">庆快校园</div>
          </el-header>
          <el-menu
            :default-active="menuactive"
            class="aside-menu"
            mode="vertical"
            background-color="#2c2e2f"
            text-color="#878888"
            active-text-color="#ffd04b"
            router
          >
            <el-menu-item
              index="/HomePage/PageRight"
              key="/HomePage/PageRight"
              to="/HomePage/PageRight"
            >
              <i class="el-icon-s-home"></i>
              <span>首页</span>
            </el-menu-item>
            <el-submenu key="storemenu" index="storemenu">
              <template slot="title">
                <i class="el-icon-s-shop"></i>
                <span>店铺管理</span>
              </template>
              <el-menu-item
                index="/HomePage/StoreLocator"
                key="/HeaderPage/StoreLocator"
                to="/HomePage/StoreLocator"
              >
                <i class="el-icon-food"></i>
                <span>店铺信息</span>
              </el-menu-item>
              <el-menu-item
                index="/HomePage/Classify"
                key="/HeaderPage/Classify"
                to="/HomePage/Classify"
              >
                <i class="el-icon-tableware"></i>
                <span>店铺位置</span>
              </el-menu-item>
              <el-menu-item
                index="/HomePage/work"
                key="/HeaderPage/work"
                to="/HomePage/work"
              >
                <i class="el-icon-dessert"></i>
                <span>店铺分类</span>
              </el-menu-item>
              <el-menu-item
                index="/HomePage/storecomment"
                key="/HeaderPage/storecomment"
                to="/HomePage/storecomment"
              >
                <i class="el-icon-notebook-2"></i>
                <span>店铺评论</span>
              </el-menu-item>
              <el-menu-item
                index="/HomePage/RecommedStore"
                key="/HeaderPage/RecommedStore"
                to="/HomePage/RecommedStore"
              >
                <i class="el-icon-notebook-2"></i>
                <span>设置推荐店铺</span>
              </el-menu-item>
            </el-submenu>
            <el-submenu key="ic" index="ic">
              <template slot="title">
                <i class="el-icon-s-ticket"></i>
                <span>IC空间管理</span>
              </template>
              <el-menu-item
                index="/HomePage/icDesignSpace"
                key="/HeaderPage/icDesignSpace"
                to="/HomePage/icDesignSpacer"
              >
                <i class="el-icon-office-building"></i>
                <span>IC空间</span>
              </el-menu-item>
              <el-menu-item
                index="/HomePage/icSpaceCategory"
                key="/HeaderPage/icSpaceCategory"
                to="/HomePage/icSpaceCategory"
              >
                <i class="el-icon-notebook-1"></i>
                <span>IC空间分类</span>
              </el-menu-item>
              <el-menu-item
                index="/HomePage/M_comment"
                key="/HeaderPage/M_comment"
                to="/HomePage/M_comment"
              >
                <i class="el-icon-notebook-2"></i>
                <span>IC空间评论管理</span>
              </el-menu-item>
            </el-submenu>
            <el-submenu key="ava" index="ava">
              <template slot="title">
                <i class="el-icon-user-solid"></i>
                <span>头像管理</span>
              </template>
              <el-menu-item
                index="/HomePage/ava"
                key="/HeaderPage/ava"
                to="/HomePage/ava"
              >
                <i class="el-icon-user"></i>
                <span>头像</span>
              </el-menu-item>
              <el-menu-item
                index="/HomePage/avacla"
                key="/HeaderPage/avacla"
                to="/HomePage/avacla"
              >
                <i class="el-icon-files"></i>
                <span>头像分类</span>
              </el-menu-item>
            </el-submenu>
            <el-submenu key="user" index="user">
              <template slot="title">
                <i class="el-icon-s-finance"></i>
                <span>用户</span>
              </template>
              <el-menu-item
                index="/HomePage/feedback"
                key="/HeaderPage/feedback"
                to="/HomePage/feedback"
              >
                <i class="el-icon-edit-outline"></i>
                <span>用户反馈</span>
              </el-menu-item>
            </el-submenu>
            <el-menu-item
              index="/HomePage/homeAd"
              key="/HeaderPage/homeAd"
              to="/HomePage/hoemeAd"
            >
              <i class="el-icon-s-platform"></i>
              广告管理
            </el-menu-item>
            <el-menu-item
              index="/HomePage/PointPack"
              key="/HeaderPage/PointPack"
              to="/HomePage/PointPack"
            >
              <i class="el-icon-sugar"></i>
              积分包管理
            </el-menu-item>
            <el-menu-item
              index="/HomePage/note"
              key="/HeaderPage/note"
              to="/HomePage/note"
            >
              <i class="el-icon-message-solid"></i>
              通知管理
            </el-menu-item>
            <el-menu-item
              index="/HomePage/AddAdmin"
              key="/HeaderPage/AddAdmin"
              to="/HomePage/AddAdmin"
            >
              <i class="el-icon-s-management"></i>
              管理员管理
            </el-menu-item>
            <el-menu-item
              index="/HomePage/toolCabinet"
              key="/HeaderPage/toolCabinet"
              to="/HomePage/toolCabinet"
            >
              <i class="el-icon-box"></i>
              工具箱
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-container>
          <el-header class="header">
            <el-dropdown placement="bottom">
              <span class="el-dropdown-link">
                <el-avatar>庆</el-avatar>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <el-button
                    @click="unlogin()"
                    type="text"
                    size="mini"
                    style="color: red"
                  >
                    退出
                  </el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-header>
          <el-main>
            <router-view></router-view>
          </el-main>
        </el-container>
      </el-container>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  components: {},
  computed: {
    menuItems() {
      return this.menu.filter((item) => !item.children);
    },
    submenus() {
      return this.menu.filter((item) => item.children);
    },
  },
  data() {
    return {
      menuactive: "/HomePage/PageRight",
      storemenu: "/HeaderPage/StoreLocator",
    };
  },
  created() {
    this.menuactive = localStorage.getItem("menuactive");
    // this.getWeather();
  },
  watch: {
    "$route.path"(newVal) {
      localStorage.setItem("menuactive", newVal);
      this.menuactive = newVal;
      console.log(this.menuactive);
    },
  },
  onHide() {},
  methods: {
    unlogin() {
      this.$router.replace({ path: "/Login" });
    },
    // 成功回调函数
    onSuccess(position) {
      console.log(position);
      var latitude = position.coords.latitude;
      var longitude = position.coords.longitude;
      console.log("Latitude: " + latitude + ", Longitude: " + longitude);
    },

    // 失败回调函数
    onError(error) {
      console.error(
        "Code: " + error.code + "\n" + "Message: " + error.message + "\n"
      );
    },
    // 调用 getCurrentPosition 方法
    getWeather() {
      // 确保 URL 正确
      if ("geolocation" in navigator) {
        console.log("Geolocation is supported");
        navigator.geolocation.getCurrentPosition(this.onSuccess, this.onError);
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
      axios
        .get(
          "http://restapi.amap.com/v3/weather/weatherInfo?key=f136a4fa383f0be4ffee40c1e16ebbf7&city=110101"
        )
        .then((res) => {
          console.log("请求天气数据成功:", res);
        })
        .catch((error) => {
          console("请求天气数据失败:", error);
        });
      axios
        .get(
          "http://restapi.amap.com/v3/geocode/geo?key=f136a4fa383f0be4ffee40c1e16ebbf7&address=北京市朝阳区阜通东大街6号&city=北京"
        )
        .then((res) => {
          console.log("请求天气数据成功:", res);
        })
        .catch((error) => {
          console("请求天气数据失败:", error);
        });
    },
  },
  mounted() {},
});
</script>

<style scoped>
.homePage {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
.homePage1 {
  width: 100vw;
  height: 100vh;
}
.aside {
  box-sizing: border-box;
  background-color: #2c2e2f;
}
.aside-header {
  box-sizing: border-box;
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ffffff;
}
.aside-header1 {
  /* width: 100%; */
  height: 100%;
  display: flex;
  align-items: center;
}
.aside-header1 img {
  width: auto;
  height: 60%;
  border-radius: 100px;
}
.title {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 10px;
  color: rgb(255, 255, 255);
  font: 400 2rem "Microsoft YaHei";
  /* font-family: "Times New Roman", Times, serif; */
  font-family: "Daoli";
}
.aside-menu {
  /* box-sizing: border-box; */
  width: 100%;
}
.aside-menu-item {
  width: 90%;
  height: 100%;
  border-radius: 30px;
}
.header {
  box-sizing: border-box;
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 50px;
  background-color: #ffffff;
}
.el-submenu /deep/.el-submenu__title:hover {
  outline: 0 !important;
  color: #ffffff !important;
  background: #434a50 !important;
}
.el-menu-item:hover {
  outline: 0 !important;
  color: #ffffff !important;
  background: #434a50 !important;
}
/*.el-menu-item.is-active {
  font-size: 1.3rem !important;
  font-weight: 600 !important;
  color: rgb(0, 54, 85) !important;
  background: rgba(131, 236, 255, 0.7) !important;
} */
::-webkit-scrollbar {
  display: none;
}
</style>
