<template>
  <div class="M_comment">
    <el-container>
      <el-header class="header al">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-upload"
          @click="add_dis = true"
        >
          新增评论
        </el-button>
        <el-button
          type="danger"
          size="small"
          icon="el-icon-delete"
          @click="deleteComment()"
        >
          删除评论
        </el-button>
      </el-header>
      <el-main class="body flex" :style="{ height: changeHeight + 'vh' }">
        <div class="body1">
          <el-table
            @selection-change="seldel"
            :data="commentList"
            style="width: 100%"
            height="100%"
            border
          >
            <el-table-column
              type="selection"
              width="50"
              align="center"
            ></el-table-column>
            <el-table-column
              label="序号"
              type="index"
              width="50"
              align="center"
            ></el-table-column>
            <el-table-column
              label="评论内容"
              prop="comment"
              align="center"
              width="600"
            ></el-table-column>
            <el-table-column
              label="创建时间"
              prop="createTime"
              align="center"
              width="250"
            ></el-table-column>
            <el-table-column
              label="创建人"
              prop="createUser"
              align="center"
            ></el-table-column>
          </el-table>
        </div>
      </el-main>
      <el-footer class="footer flex">
        <el-pagination
          layout="prev, pager, next"
          :total="totalCommentList.length"
          :page-size="14"
          @current-change="changepage"
        ></el-pagination>
      </el-footer>
      <el-dialog
        :visible.sync="add_dis"
        width="30%"
        :append-to-body="true"
        title="新增评论"
        center
      >
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 10 }"
          v-model="add_comment"
          maxlength="300"
          show-word-limit
          placeholder="请输入评论内容"
        >
        </el-input>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="add_sure()">发 布</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title=""
        :visible.sync="del_dis"
        width="25%"
        style="height: 300vh"
        :append-to-body="true"
      >
        <div style="display: flex; justify-content: center; font-size: 1.5rem">
          确定要删除选中项吗
        </div>
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 50px;
          "
        >
          <el-button type="primary" @click="del_sure()"> 确定 </el-button>
        </div>
      </el-dialog>
    </el-container>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
export default Vue.extend({
  components: {},
  data() {
    return {
      token: "",
      now_page: 1,
      add_dis: false,
      add_comment: "",
      changeHeight: 75,
      totlaCommentList: [],
      commentList: [],
      del_dis: false,
      deletelist: [],
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getCommentList();
  },
  computed: {},
  mounted() {
    let th = this;
    let lastPixelRatio = window.devicePixelRatio;
    window.addEventListener("resize", function () {
      let currentPixelRatio = window.devicePixelRatio;

      if (currentPixelRatio !== lastPixelRatio) {
        console.log("页面缩放变化了");
        if (currentPixelRatio > 1.5) {
          th.changeHeight = 75 - 10 * (currentPixelRatio - 1.5);
          console.log(th.changeHeight);
        } else {
          th.changeHeight = 75;
        }
      }
      lastPixelRatio = currentPixelRatio;
    });
  },
  methods: {
    getCommentList() {
      axios
        .get("/api/admin/ic-space-default-comment", {
          headers: {
            token: this.token,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.totalCommentList = res.data.data;
            this.commentList = this.totalCommentList.slice(
              (this.now_page - 1) * 14,
              this.now_page * 14
            );
          }
        });
    },
    changepage(page = this.now_page) {
      this.now_page = page;
      this.commentList = this.totalCommentList.slice(
        (this.now_page - 1) * 14,
        this.now_page * 14
      );
    },
    add_sure() {
      if (this.add_comment == "") {
        this.$message({
          type: "warning",
          message: "请输入评论内容",
        });
      } else {
        axios
          .post(
            "/api/admin/ic-space-default-comment",
            { comment: this.add_comment },
            {
              headers: {
                token: this.token,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            if (res.data.code == 1) {
              this.add_dis = false;
              this.$message({
                type: "success",
                message: "新增成功",
              });
              this.getCommentList();
            } else {
              this.$message.error("新增失败");
            }
          })
          .catch((err) => {
            this.$message.error("新增失败");
          })
          .finally(() => {
            this.add_comment = "";
            this.add_dis = false;
          });
      }
    },
    seldel(e) {
      this.deletelist = e;
    },
    deleteComment() {
      if (this.deletelist.length <= 0) {
        this.$message({ message: "未选中任何项", type: "error" });
      } else {
        this.del_dis = true;
      }
    },
    del_sure() {
      this.deleteIdlist = [];
      this.deletelist.forEach((item) => {
        this.deleteIdlist.push(item.id);
      });
      let ids = this.deleteIdlist; // 假设这是一个包含ID的数组
      let idStr = ids.join(",");
      axios
        .delete(`/api/admin/ic-space-default-comment`, {
          headers: {
            token: this.token,
          },
          params: {
            ids: idStr,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getCommentList();
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch((err) => {
          this.$message.error("删除失败");
        })
        .finally(() => {
          this.del_dis = false;
        });
    },
  },
  watch: {},
});
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flrow {
  display: flex;
  flex-direction: row;
}
.al {
  display: flex;
  align-items: center;
}
.js {
  display: flex;
  justify-content: center;
}
.M_comment {
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
}
.header {
  width: 100%;
  height: 8%;
  justify-content: flex-start;
  padding-left: 70px;
}

.body {
  width: 100%;
  /* height: 75vh; */
}
/* @media (min-width: 600px) and (max-width: 1200px) {
  .body {
    width: 100%;
    height: 75vh;
  }
} */
.body1 {
  width: 90%;
  height: 100%;
}
.footer {
  width: 100%;
  padding-top: 0rpx;
  padding-bottom: 0rpx;
}
</style>
