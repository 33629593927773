<template>
  <div class="PageRight">
    <el-container
      style="
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        border-bottom: 1px solid #d0cece;
        overflow-y: scroll;
        flex: 1;
      "
    >
      <el-main class="el-main" style="flex: 1">
        <!-- <div class="func">
          <div style="width: 90%">
            <el-collapse>
              <el-collapse-item title="清除缓存">
                <el-button
                  type="info"
                  size="small"
                  @click="showclear()"
                  icon="el-icon-delete"
                >
                  清除缓存
                </el-button>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>

        <div class="func">
          <div style="width: 90%">
            <el-collapse>
              <el-collapse-item title="图片压缩网站" name="1">
                <div class="content">
                  <a
                    href="https://www.imgdiet.com/zh-CN/jpg-compress"
                    target="_blank"
                  >
                    https://www.imgdiet.com/zh-CN/jpg-compress
                  </a>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
        <div class="func">
          <div style="width: 90%">
            <el-collapse>
              <el-collapse-item title="获取图片CDN链接" name="2">
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="fileUpload"
                    :auto-upload="false"
                    :on-change="ImageSelect"
                    action="aaa"
                  >
                    <el-button slot="trigger" size="small" type="primary">
                      选取文件
                    </el-button>
                    <div slot="tip" class="el-upload__tip">
                      只能上传jpg/png文件
                    </div>
                  </el-upload>
                </div>
                <div class="imagePath" style="margin-top: 20px">
                  <input
                    class="imageCDN"
                    v-model="imagePath"
                    placeholder="这里显示图片CDN链接"
                  />
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div> -->
        <el-main class="yesterdayData">
          <el-header style="font-size: 1.2rem">昨日核心数据</el-header>
          <el-main class="yesterdayData1">
            <div class="Dataitem">
              <div class="Dataitem1">累计用户数</div>
              <div class="Dataitem2 flex">{{ totalUser.totalUsers }}</div>
              <div class="Dataitem3 flrow">
                <div style="color: #9a9a9a">日:</div>
                <div class="js" style="color: #2cca78; flex-grow: 1">
                  {{ totalUser.dailyGrowth }}%
                </div>
              </div>
              <div class="Dataitem4 flrow">
                <div style="color: #9a9a9a">周:</div>
                <div class="js" style="color: #2cca78; flex-grow: 1">
                  {{ totalUser.weeklyGrowth }}%
                </div>
              </div>
              <div class="Dataitem5 flrow">
                <div style="color: #9a9a9a">月:</div>
                <div class="js" style="color: #2cca78; flex-grow: 1">
                  {{ totalUser.monthlyGrowth }}%
                </div>
              </div>
            </div>
            <div class="Dataitem">
              <div class="Dataitem1">小程序访问量</div>
              <div class="Dataitem2 flex">{{ pageView.totalHits }}</div>
              <div class="Dataitem3 flrow">
                <div style="color: #9a9a9a">日:</div>
                <div class="js" style="color: #2cca78; flex-grow: 1">
                  {{ pageView.dailyGrowth }}%
                </div>
              </div>
              <div class="Dataitem4 flrow">
                <div style="color: #9a9a9a">周:</div>
                <div class="js" style="color: #2cca78; flex-grow: 1">
                  {{ pageView.weeklyGrowth }}%
                </div>
              </div>
              <div class="Dataitem5 flrow">
                <div style="color: #9a9a9a">月:</div>
                <div class="js" style="color: #2cca78; flex-grow: 1">
                  {{ pageView.monthlyGrowth }}%
                </div>
              </div>
            </div>
            <div class="Dataitem">
              <div class="Dataitem1">进店访问</div>
              <div class="Dataitem2 flex">{{ eStore.totalHits }}</div>
              <div class="Dataitem3 flrow">
                <div style="color: #9a9a9a">日:</div>
                <div class="js" style="color: #2cca78; flex-grow: 1">
                  {{ eStore.dailyGrowth }}%
                </div>
              </div>
              <div class="Dataitem4 flrow">
                <div style="color: #9a9a9a">周:</div>
                <div class="js" style="color: #2cca78; flex-grow: 1">
                  {{ eStore.weeklyGrowth }}%
                </div>
              </div>
              <div class="Dataitem5 flrow">
                <div style="color: #9a9a9a">月:</div>
                <div class="js" style="color: #2cca78; flex-grow: 1">
                  {{ eStore.monthlyGrowth }}%
                </div>
              </div>
            </div>
            <div class="Dataitem">
              <div class="Dataitem1">新增用户量</div>
              <div class="Dataitem2 flex">{{ addUser.newUsers }}</div>
              <div class="Dataitem3 flrow">
                <div style="color: #9a9a9a">日:</div>
                <div class="js" style="color: #2cca78; flex-grow: 1">
                  {{ addUser.dailyGrowth }}%
                </div>
              </div>
              <div class="Dataitem4 flrow">
                <div style="color: #9a9a9a">周:</div>
                <div class="js" style="color: #2cca78; flex-grow: 1">
                  {{ addUser.weeklyGrowth }}%
                </div>
              </div>
              <div class="Dataitem5 flrow">
                <div style="color: #9a9a9a">月:</div>
                <div class="js" style="color: #2cca78; flex-grow: 1">
                  {{ addUser.monthlyGrowth }}%
                </div>
              </div>
            </div>
          </el-main>
        </el-main>
        <el-main class="weekStoreData">
          <div id="lastweekchart" style="width: 50%; height: 350px"></div>
          <div id="thisweekchart" style="width: 50%; height: 350px"></div>
        </el-main>
        <el-main class="userStatistics">
          <div
            id="userDetail"
            style="width: 100%; height: 350px"
            text-color="#f4eb66"
          ></div>
          <el-footer class="userfooter js">
            <div
              class="js"
              v-for="item in selDateList"
              :key="item.value"
              @click="selDate(item.value)"
            >
              <div
                class="selDateitem"
                :style="{
                  backgroundColor:
                    selIndex == item.value ? item.color : '#d9d9d9',
                }"
              ></div>
              <div
                class="selDateText tl"
                :style="{
                  color: selIndex == item.value ? '#919191' : '#d9d9d9',
                }"
              >
                {{ item.text }}
              </div>
            </div>
          </el-footer>
        </el-main>
      </el-main>
      <el-footer class="el-footer" style="width: 100%; height: 14%">
        <div id="pmotto">有三秋桂子,十里荷花</div>
        <div class="flrow">
          <span id="pname">庆快校园备忘录</span>
          <span id="picp">粤ICP备2024270005号</span>
          <span id="ph">版权所有: 庆快校园项目组</span>
        </div>
        <div id="time" class="flrow">
          <div style="padding-right: 30px">网站已稳定运行:</div>
          <div class="number">{{ days }}</div>
          <div class="unit">天</div>
          <div class="number">{{ hours }}</div>
          <div class="unit">小时</div>
          <div class="number">{{ minutes }}</div>
          <div class="unit">分钟</div>
          <div class="number">{{ seconds }}</div>
          <div class="unit">秒</div>
        </div>
      </el-footer>
    </el-container>

    <el-dialog
      title="确定要清除缓存吗"
      :visible.sync="del_dis"
      width="25%"
      :append-to-body="true"
      center
    >
      <div style="display: flex; justify-content: center; font-size: 1.5rem">
        该操作不可逆，请谨慎考虑
      </div>
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 50px;
        "
      >
        <el-button type="primary" @click="clear()"> 确定清除 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  components: {},
  data() {
    return {
      token: "",
      imagePath: "",
      del_dis: false,
      fixedTime: null,
      timeDifference: 0,
      totalUser: "", //用户量
      pageView: "", //访问量
      eStore: "", //进店访问量
      addUser: "", //新增用户量
      lastweekTop10: {
        tooltip: {
          trigger: "item", // 对于饼图，使用 'item' 触发类型
          formatter: function (params) {
            return "点击量: " + params.value;
          },
        },
        title: {
          text: "上周店铺访问top10",
        },
        series: [
          {
            type: "pie",
            data: [],
            radius: "50%",
          },
        ],
      },
      thisweekTop10: {
        tooltip: {
          trigger: "item", // 对于饼图，使用 'item' 触发类型
          formatter: function (params) {
            return "点击量: " + params.value;
          },
        },
        title: {
          text: "本周店铺访问top10",
        },
        series: [
          {
            type: "pie",
            data: [],
            radius: "50%",
          },
        ],
      },
      dateList: [],
      newUserList: [],
      totalUserList: [],
      selIndex: 1,
      selDateList: [
        {
          value: 1,
          text: "七天内",
          color: "#19e8a1",
        },
        {
          value: 2,
          text: "1个月内",
          color: "#fef200",
        },
        {
          value: 3,
          text: "3个月内",
          color: "#4cb3ea",
        },
      ],
    };
    loadingInstance: null;
  },
  watch: {},

  created() {
    this.token = localStorage.getItem("token");
    this.getTotalUser();
    this.getLastWeekData();
    this.getThisWeekData();
    this.getUserData(7);
    this.$nextTick(() => {
      this.initLastWeekChart();
      this.initThisWeekChart();
      this.initUserChartDetail();
    });
  },
  mounted() {
    // this.initLastWeekChart();
    // this.initThisWeekChart();
    // this.initUserChartDetail();
    this.fixedTime = new Date("2024-06-31T23:59:59Z").getTime();
    this.timeInterval = setInterval(this.updateTimeDifference, 1000);
  },
  computed: {
    days() {
      const totalSeconds = Math.floor(this.timeDifference / 1000);
      return Math.floor(totalSeconds / (60 * 60 * 24));
    },
    hours() {
      const totalSeconds = Math.floor(this.timeDifference / 1000);
      return Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
    },
    minutes() {
      const totalSeconds = Math.floor(this.timeDifference / 1000);
      return Math.floor((totalSeconds % (60 * 60)) / 60);
    },
    seconds() {
      const totalSeconds = Math.floor(this.timeDifference / 1000);
      return totalSeconds % 60;
    },
  },
  methods: {
    initLastWeekChart() {
      const element = document.getElementById("lastweekchart");
      // 如果已存在实例，则先销毁
      if (this.lastWeekChartInstance) {
        this.lastWeekChartInstance.dispose();
      }
      // 初始化新的图表实例
      this.lastWeekChartInstance = this.$echarts.init(element);
      // 设置图表选项...
      const option = this.lastweekTop10;
      this.lastWeekChartInstance.setOption(option);
    },
    initThisWeekChart() {
      const element = document.getElementById("thisweekchart");
      // 如果已存在实例，则先销毁
      if (this.thisWeekChartInstance) {
        this.thisWeekChartInstance.dispose();
      }
      // 初始化新的图表实例
      this.thisWeekChartInstance = this.$echarts.init(element);
      // 设置图表选项...
      const option = this.thisweekTop10;
      this.thisWeekChartInstance.setOption(option);
    },
    initUserChartDetail() {
      const element = document.getElementById("userDetail");
      // 如果已存在实例，则先销毁
      if (this.userDetailChartInstance) {
        this.userDetailChartInstance.dispose();
      }
      // 初始化新的图表实例
      this.userDetailChartInstance = this.$echarts.init(element);
      // 设置图表选项...
      let option = {
        title: { text: "实时数据分析" },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            let result = "";
            params.forEach(function (item) {
              // 根据系列使用的 yAxisIndex 来判断是哪个 Y 轴的数据
              let yAxisName = item.yAxisIndex === 0 ? "总量" : "增量";
              result += item.seriesName + " : " + item.data + "<br/>";
            });
            return result;
          },
        },
        xAxis: {
          data: this.dateList,
        },
        yAxis: [
          {
            // 左侧 Y 轴
            type: "value",
            name: "总量",
            position: "left", // 设置在左侧
            axisLine: {
              lineStyle: {
                color: "#434343",
              },
            },
            axisLabel: {
              formatter: "{value}",
            },
          },
          {
            // 右侧 Y 轴
            type: "value",
            name: "增量",
            position: "right", // 设置在右侧
            axisLine: {
              lineStyle: {
                color: "#434343",
              },
            },
            axisLabel: {
              formatter: "{value}",
            },
          },
        ],
        series: [
          {
            name: "用户总量",
            data: this.totalUserList,
            type: "line",
            smooth: true,
            yAxisIndex: 0, // 使用左侧 Y 轴
            areaStyle: {
              color: "#eddd00",
              opacity: 0.2,
            },
            lineStyle: {
              width: 3,
              color: "#eddd00",
            },
            itemStyle: {
              color: "#eddd00",
            },
            // 如果不需要堆叠效果，可以移除或修改 stack 属性
            // stack: "x",
          },
          {
            name: "用户增量",
            data: this.newUserList,
            type: "line",
            smooth: true,
            yAxisIndex: 1, // 使用右侧 Y 轴
            areaStyle: {
              color: "#16d393",
              opacity: 0.2,
            },
            lineStyle: {
              width: 3,

              color: "#16d393",
            },
            itemStyle: {
              color: "#16d393",
            },
            // 如果不需要堆叠效果，可以移除或修改 stack 属性
            // stack: "x",
          },
        ],
      };
      this.userDetailChartInstance.setOption(option);
      if (this.loadingInstance) {
        this.loadingInstance.close();
        this.loadingInstance = null;
      }
    },
    //公共GET请求函数
    async axiosGet(url) {
      try {
        const res = await axios.get(`/api${url}`, {
          headers: {
            "Content-Type": "application/json",
            token: this.token,
          },
        });

        if (res.data.code === 1) {
          return res.data.data;
        } else {
          throw new Error("获取数据失败，状态码：" + res.data.code);
        }
      } catch (error) {
        this.$message.error("请求出错，请稍后再试");
        throw error;
      }
    },
    async getAddUser() {
      //请求新增用户量
      try {
        this.addUser = await this.axiosGet(
          "/admin/work-space/user/statistics-new-user"
        );
      } catch (error) {
        this.$message.error("获取数据失败");
      }
    },
    async geteStore() {
      //请求进店访问量
      try {
        this.eStore = await this.axiosGet(
          "/admin/work-space/hits/statistics-hits"
        );
        this.getAddUser();
      } catch (error) {
        this.$message.error("获取数据失败");
      }
    },
    async getVisit() {
      //请求访问量
      try {
        this.pageView = await this.axiosGet(
          "/admin/work-space/hits/statistics-daily-visits"
        );
        this.geteStore();
      } catch (error) {
        this.$message.error("获取数据失败");
      }
    },
    async getTotalUser() {
      //请求总用户量
      try {
        this.totalUser = await this.axiosGet(
          "/admin/work-space/user/statistics-all-user"
        );
        this.getVisit();
      } catch (error) {
        this.$message.error("获取数据失败");
      }
    },
    getLastWeekData() {
      let th = this;
      //请求上周用户量
      axios
        .get(
          "/api/admin/work-space/hits/top10-lastWeek-circularDiagram-statistics",
          {
            headers: {
              "Content-Type": "application/json",
              token: this.token,
            },
          }
        )
        .then((res) => {
          if (res.data.code == 1) {
            th.lastweekTop10.series[0].data = [];
            res.data.data.forEach((item) => {
              let dataitem = {
                value: item.hitsCount,
                name: item.storeName,
              };
              th.lastweekTop10.series[0].data.push(dataitem);
              th.initLastWeekChart();
            });
          } else {
            this.$message.error("获取数据失败");
          }
        });
    },
    getThisWeekData() {
      let th = this;
      //请求本周用户量
      axios
        .get(
          "/api/admin/work-space/hits/top10-thisWeek-circularDiagram-statistics",
          {
            headers: {
              "Content-Type": "application/json",
              token: this.token,
            },
          }
        )
        .then((res) => {
          if (res.data.code == 1) {
            th.thisweekTop10.series[0].data = [];
            res.data.data.forEach((item) => {
              let dataitem = {
                value: item.hitsCount,
                name: item.storeName,
              };
              th.thisweekTop10.series[0].data.push(dataitem);
              th.initThisWeekChart();
            });
          } else {
            this.$message.error("获取数据失败");
          }
        });
    },
    getUserData(differ) {
      let th = this;
      let beginDate = new Date();
      beginDate.setDate(beginDate.getDate() - differ);
      let endDate = new Date();
      let begin = beginDate.toISOString().split("T")[0];
      let end = endDate.toISOString().split("T")[0];
      let config = {
        params: {
          begin: begin,
          end: end,
        },
        headers: {
          token: this.token,
        },
      };
      axios
        .get("/api/admin/work-space/user/chart-statistics", config)
        .then((res) => {
          if (res.data.code == 1) {
            this.dateList = res.data.data.dateList.split(",");
            this.newUserList = res.data.data.newUserList.split(",");
            this.totalUserList = res.data.data.totalUserList.split(",");
            th.initUserChartDetail();
          } else {
            this.$message.error("获取数据失败");
          }
        })
        .catch((err) => {});
      setTimeout(() => {
        if (th.loadingInstance) {
          th.loadingInstance.close();
          th.loadingInstance = null; // 可选：将实例设置为 null 以表示它已被关闭
        }
      }, 20000);
    },
    selDate(e) {
      if (this.selIndex != e) {
        this.selIndex = e;
        this.loadingInstance = this.$loading({
          lock: true,
          text: "加载数据中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        switch (e) {
          case 1: {
            this.getUserData(7);
            break;
          }
          case 2: {
            this.getUserData(30);
            break;
          }
          case 3: {
            this.getUserData(90);
            break;
          }
        }
      }
    },
    updateTimeDifference() {
      if (this.fixedTime) {
        this.timeDifference = Date.now() - this.fixedTime;
      }
    },
    showclear() {
      this.del_dis = true;
    },

    clear() {
      axios
        .post("/api/admin/common/clearHomeImageCache", null, {
          headers: {
            "Content-Type": "application/json",
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: res.data.data,
              type: "success",
            });
          } else {
            this.$message.error("清除失败");
          }
        });
    },

    async ImageSelect(event) {
      const file = event.raw;
      let img = await this.Fileimage(file);
      this.imagePath = img.data;
    },
    async Fileimage(file) {
      const FormData = require("form-data");
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`, // 边界是自动设置的，但为了某些特殊情况，您可以手动设置它
          token: this.token, // 假设 this.token 是您的认证令牌
        },
      };
      const res = await axios.post(
        "/api/admin/common/upload",
        formData,
        config
      );
      return res.data;
    },
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
  },
});
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flrow {
  display: flex;
  flex-direction: row;
}
.js {
  display: flex;
  justify-content: center;
}
.al {
  display: flex;
  align-items: center;
}
.tl {
  display: flex;
  text-align: center;
}
.PageRight {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}
.el-main {
  box-sizing: border-box;
  width: 100%;
  /* height: 75%; */
}
.yesterdayData {
  width: 100%;
  height: 300px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 0px 3px #cecdcd;
}
.yesterdayData1 {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.Dataitem1 {
  font-size: 1.5rem;
  color: #9a9a9a;
}
.Dataitem2 {
  margin-top: 10px;
  font-size: 2rem;
  color: #353535;
}
.Dataitem3 {
  margin-top: 10px;
}
.Dataitem4 {
  margin-top: 10px;
}
.Dataitem5 {
  margin-top: 10px;
}
.weekStoreData {
  display: flex;
  margin-top: 20px;
  width: 100%;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 0px 3px #cecdcd;
}
.userStatistics {
  width: 100%;
  margin-top: 20px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 0px 3px #cecdcd;
}
.userfooter {
  display: flex;
  align-items: center;
  width: 100%;
}
.selDateitem {
  width: 17px;
  height: 15px;
  border-radius: 2px;
  background-color: #c5c5c5;
  margin-left: 30px;
}
.selDateText {
  height: 15px;
  margin-left: 5px;
  font-size: 0.8rem;
  color: #9a9a9a;
}
.el-footer {
  width: 100%;
  height: 30%;
}
.func {
  box-sizing: border-box;
  width: 100%;
  margin-top: 30px;
  padding-left: 5%;
  font-size: 1.5rem;
}
.imagePath .imageCDN {
  display: flex;
  width: 90%;
  font-size: 1.3rem;
  color: #551aa9;
}
#pmotto {
  padding-top: 5px;
  padding-bottom: 10px;
  font-size: 1.1rem;
  font-family: "ShuHei";
}
#pname {
  padding-right: 30px;
  padding-bottom: 10px;
  font-weight: bolder;
}
#picp {
  padding-right: 30px;

  padding-bottom: 10px;
}
#ph {
  color: #605f5f;
}
#time {
  padding-bottom: 10px;
}
.number {
  padding-right: 2px;
  color: rgb(255, 0, 0);
}
.unit {
  padding-right: 4px;
}
::-webkit-scrollbar {
  width: 0;
}
</style>
