<template>
  <div class="login">
    <div class="header flrow al">
      <div class="header_name">庆快校园</div>
      <div class="header_op flrow al">
        <div class="op" @click="login_dis = true">登录</div>
        <div class="op1" style="margin-left: 10px">|</div>
        <div
          class="op"
          style="margin-left: 10px"
          @click="miniprogram_dis = true"
        >
          小程序端
        </div>
      </div>
    </div>
    <div class="body">
      <div class="body_header"></div>
      <div class="body_body flex">
        <div class="body_bg">
          <div
            v-for="(item, index) in 15"
            :key="index"
            class="star"
            :style="getStarStyle(index)"
          ></div>
          <div class="moon"></div>
          <div class="bg_txt">
            无论相隔多远, 我们抬头仰望的, 仍然是同一片星空
          </div>
        </div>
        <div class="change_body flrow">
          <div
            :class="body_item_index == 1 ? 'change_body_item_active' : ''"
            class="change_body_item margin_right flrow"
            @mouseenter="change_body_item_index(1)"
          >
            <div class="txt" :class="body_item_index == 1 ? 'txt_active' : ''">
              <div class="" style="width: 90%; height: 80%; overflow-y: scroll">
                庆快校园是一款基于前后端分离技术栈开发的综合性工具，通过管理庆快小程序端与后台管理系统相结合，实现便捷小程序运营管理的无缝对接，它不仅简化了点餐流程，还提升了广州大学商业中心饮食店铺的运营效率和顾客满意度。
              </div>
            </div>
            <div class="title">关于庆快</div>
          </div>
          <div
            :class="body_item_index == 2 ? 'change_body_item_active' : ''"
            class="change_body_item margin_right flrow"
            @mouseenter="change_body_item_index(2)"
          >
            <div
              class="txt operationWithMe"
              :class="body_item_index == 2 ? 'txt_active' : ''"
            ></div>
            <div class="title">与我合作</div>
          </div>
          <div
            :class="body_item_index == 3 ? 'change_body_item_active' : ''"
            class="change_body_item margin_right flrow"
            @mouseenter="change_body_item_index(3)"
          >
            <div
              class="txt joinus"
              :class="body_item_index == 3 ? 'txt_active' : ''"
            ></div>
            <div class="title">加入我们</div>
          </div>
          <div
            :class="body_item_index == 4 ? 'change_body_item_active' : ''"
            class="change_body_item margin_right flrow"
            @mouseenter="change_body_item_index(4)"
          >
            <div class="txt" :class="body_item_index == 4 ? 'txt_active' : ''">
              <div
                class=""
                style="
                  color: black;
                  width: 90%;
                  height: 80%;
                  overflow-y: scroll;
                "
              >
                我们是一群由兴趣驱动的开发者，怀揣着对技术的无限热爱与追求，走到了一起。在这个充满挑战与机遇的数字时代，我们不仅仅是为了工作而聚在一起，更是为了共同的兴趣和梦想——用代码编织未来，创造更加便捷、有趣且富有创意的数字产品。
                从最初的网页设计，到后来的小程序开发，我们始终保持着对技术的敏锐洞察力和持续学习的热情。我们相信，每一个网页、每一个小程序，都是连接用户与世界的桥梁，是展现创意与智慧的舞台。因此，我们始终将用户体验放在首位，用心去感受用户的需求，用技术去满足他们的期待。
              </div>
            </div>
            <div class="title">关于我们</div>
          </div>
          <div
            :class="body_item_index == 5 ? 'change_body_item_active' : ''"
            class="change_body_item flrow"
            @mouseenter="change_body_item_index(5)"
          >
            <div
              class="txt callme"
              :class="body_item_index == 5 ? 'txt_active' : ''"
            >
              <img src="" alt="" />
            </div>
            <div class="title">联系我们</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer_body">
        <div
          class="bubble"
          v-for="item in 70"
          :key="item"
          :style="getbubbleStyle()"
        ></div>
      </div>
      <div class="InternetContentProvider flex">
        <div class="copyright flex">版权所有: 庆快校园项目组</div>
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          style="color: #2143b4; text-decoration: underline"
        >
          粤ICP备2024270005号
        </a>
      </div>
    </div>
    <div class="login_page flex" v-if="login_dis" @click="close_loginPage()">
      <div class="login_page_txt login_shadow">
        <el-header class="login_page_header flex">登录</el-header>
        <el-form class="login_form">
          <el-input
            class="login_input"
            style="margin-bottom: 25px; margin-top: 20px"
            placeholder="输入账号"
            v-model="loginForm.username"
            clearable
          >
          </el-input>
          <el-input
            class="login_input"
            style="margin-bottom: 30px"
            placeholder="请输入密码"
            v-model="loginForm.password"
            show-password
          ></el-input>

          <!-- <el-button class="login_btn" type="primary" @click.prevent="Login()">
            登录
          </el-button> -->
          <div @click.prevent="Login()">
            <ubotton></ubotton>
          </div>
        </el-form>
        <!-- <div class="login_page_bg">
          <div class="login_page_bg_change"></div>
        </div> -->
      </div>
    </div>
    <div
      class="miniprogram flex"
      v-if="miniprogram_dis"
      @click="show_programqrCode()"
    >
      <div class="programqrCode">
        <div class="programqrcode_tip">
          <button @click="miniprogram_dis = false">×</button>
        </div>
        <div class="programqrCode_image flex">
          <img src="../../assets/小程序码.png" alt="" />
        </div>
        <div class="flex" style="width: 100%; height: 50px">扫一扫体验</div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import _ from "lodash";
import ubotton from "../../D_element/ubutton.vue";
export default Vue.extend({
  name: "",
  components: { ubotton },
  props: {},
  data() {
    return {
      color_list: [
        "#00ffea",
        "rgb(39, 152, 244)",
        "#ff8000",
        "#0062ff",
        "#ff00e6",
        "#00c3ff",
      ],
      body_item_index: 1,
      login_dis: false,
      miniprogram_dis: false,
      loginForm: {
        username: "",
        password: "",
      },
      loginRules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    getStarStyle(index) {
      // 根据索引动态计算位置
      let color = this.color_list[_.random(0, 3)]; // 根据索引选择颜色
      let colorStart = this.color_list[_.random(0, this.color_list.length - 1)]; // 随机选择起始颜色
      const fixedColorEnd = "#2c4a61"; // 固定的结束颜色
      // const topPosition = 150 + _.random(-100, 100); // 每个星星向下移动20px
      // 水平偏移范围 (-100, -10) 和 (10, 100)
      let topOffset;
      if (_.random(0, 1) === 0) {
        topOffset = _.random(-270, -20); // 选择 (-100, -10)
      } else {
        topOffset = _.random(20, 270); // 选择 (10, 100)
      }
      const topPosition = 150 + topOffset;
      // 水平偏移范围 (-100, -10) 和 (10, 100)
      let leftOffset;
      if (_.random(0, 1) === 0) {
        leftOffset = _.random(-70, -20); // 选择 (-100, -10)
      } else {
        leftOffset = _.random(20, 70); // 选择 (10, 100)
      }
      const leftPosition = 50 + leftOffset;
      return {
        top: `${topPosition}px`,
        left: `${leftPosition}vw`,
        transform: "rotate(-45deg)",
        transformOrigin: "center",
        background: `linear-gradient(to right, ${colorStart}, ${fixedColorEnd})`,
      };
    },
    getbubbleStyle() {
      let width = _.random(10, 50);
      let left = _.random(5, 95);
      let i = _.random(10, 50);
      return {
        width: `${width}px`,
        height: `${width}px`,
        borderRaduis: `${width}px`,
        left: `${left}vw`,
        "--i": i,
      };
    },
    Login() {
      console.log("登录");

      if (this.loginForm.username == "") {
        this.$message.error("请输入账号");
        return;
      }
      if (this.loginForm.password == "") {
        this.$message.error("请输入密码");
        return;
      }

      axios
        .post("/api/admin/employee/login", this.loginForm)
        .then((res) => {
          if (res.data.code == 1) {
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem("id", res.data.data.id);
            localStorage.setItem("userName", res.data.data.userName);
            localStorage.setItem(
              "classification",
              res.data.data.classification
            );

            console.log("登录成功");
            localStorage.setItem("menuactive", "/HomePage/PageRight");
            this.$router.replace("/HomePage/PageRight").catch((err) => {});
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message.error("连接超时，请检查网络连接");
        });
    },
    change_body_item_index(index) {
      this.body_item_index = index;
    },
    close_loginPage() {
      if (!event.target.closest(".login_page_txt")) {
        this.login_dis = false;
      }
    },
    show_programqrCode() {
      if (!event.target.closest(".programqrCode")) {
        this.miniprogram_dis = false;
      }
    },
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {
    console.log("destroyed");
  },
  mounted() {},
  watch: {},
  computed: {},
  filters: {},
  created() {},
});
</script>
<style scoped>
.login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flrow {
  display: flex;
  flex-direction: row;
}
.al {
  display: flex;
  align-items: center;
}
.js {
  display: flex;
  justify-content: center;
}
.header {
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 10%;
  padding: 0px 50px 0px 100px;
  position: fixed;
  top: 0;
  left: 0;
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.25)
  );
  border: 1rpx solid #e9e7e7;
  z-index: 1000;
}
.header_name {
  position: relative;
  font-size: 45px;
  font-family: "Daoli";
  /* text-shadow: 3px 3px 4px rgba(205, 205, 205, 0.87); */
  color: #00d9ffb5;
}

.header_name::after {
  content: "庆快校园";
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 45px;
  color: #000000;
  z-index: -1;
  transform: translate(-20px, 10px) scaleY(0.5) skew(50deg);
  filter: blur(1px);
  mask: linear-gradient(transparent, #000);
}
.header_op {
  font-size: 1.3rem;
  font-weight: 550;
  font-family: "Shuhei";
  color: #00d9ffd1;
}
.op1 {
  font-size: 1rem;
}
.op {
  font-size: 1.6rem;
}
.body {
  width: 100%;
  height: 75%;
  background-color: #2c4a61;
}
.body_header {
  width: 100%;
  height: 13.4%;
}
.body_body {
  width: 100%;
  height: 86.6%;
}
.body_bg {
  overflow: hidden;
  box-sizing: border-box;
  position: absolute;
  top: 11vh;
  left: 0px;
  width: 100%;
  height: 60%;
  /* background-color: #3a3939; */
}
.moon {
  position: relative;
  top: 100px;
  left: 100px;
  width: 300px;
  height: 300px;
  background-color: rgb(39, 152, 244);
  border-radius: 50%;
  box-shadow: 0px 0px 60px 30px rgb(39, 152, 244);
  animation: shadowPulse 4s infinite linear;
}
@keyframes shadowPulse {
  0% {
    box-shadow: 0px 0px 40px 20px rgb(39, 152, 244);
  }
  50% {
    box-shadow: 0px 0px 60px 40px rgb(39, 152, 244);
  }
  100% {
    box-shadow: 0px 0px 40px 20px rgb(39, 152, 244);
  }
}

.star {
  position: relative;
  /* top: 150px; */
  /* left: 50vw; */
  width: 500px;
  height: 5px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #00c3ff;
  background: linear-gradient(to right, rgb(39, 152, 244), #2c4a61);
  transform: rotate(-45deg); /* 旋转45度 */
  transform-origin: center; /* 设置旋转中心为元素的中心 */
  /* animation: changetail 4s infinite linear; */
}
@keyframes changetail {
  0% {
    width: 500px;
  }
  50% {
    width: 200px;
  }
  100% {
    width: 500px;
  }
}
.bg_txt {
  position: fixed;
  top: 11.5vh;
  right: 100px;
  color: #fff;
  font-weight: 700;
  font-size: 1.5rem;
  font-family: Daoli;
}
.login_page {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: 1500;
  background-color: rgba(255, 255, 255, 0);
}

.login_page_txt {
  width: 350px;
  height: 400px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #e3e3e3;
}
.login_shadow {
  box-shadow: -5px 5px 0px #3e3e3e;
}
.miniprogram {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: 1501;
  background-color: rgba(255, 255, 255, 0);
}
.programqrCode {
  width: 300px;
  height: 350px;
  background-color: #ffffff;
}
.programqrcode_tip {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 50px;
  padding-right: 10px;
}
.programqrCode_image img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}
.programqrcode_tip button {
  font-size: 30px;
  border: 0px solid #fff;
  background-color: #fff;
}
.programqrCode {
  width: 300px;
  height: 300px;
}
.login_page_header {
  width: 100%;
  height: 30%;
  font-size: 2.5rem;
  font-weight: bolder;
  font-family: ShuHei;
  color: #0280d4;
}
.login_form {
  box-sizing: border-box;
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login_input {
  width: 70%;
  height: 50px;
  border: 0px solid #fff;
}
.login_btn {
  width: 70%;
}

/* .login_page_bg {
  overflow: hidden;
  width: 100%;
  height: 15%;
  padding-top: 50px;
} */
.login_page_bg_change {
  width: 100%;
  height: 300%;
  border-radius: 50px;
  background-color: #00d9ff;
  position: relative;
  transform-origin: center; /* 设置旋转中心为元素的中心 */
  animation: rotateAnimation 5s linear infinite; /* 应用旋转动画 */
}
@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.change_body {
  width: 60%;
  height: 70%;
  z-index: 520;
}
.change_body_item {
  border-radius: 10px;
  width: 8vw;
  height: 100%;
  background-color: #0280d4;
  transition: width 0.3s ease, background-color 0.3s ease; /* 定义过渡效果 */
}
.margin_right {
  margin-right: 2.5vw;
}
/* .change_body_item:hover {
  width: 18vw;
  background-color: #0ba0e3;
} */
.change_body_item_active {
  width: 18vw;
  height: 100%;
  background-color: #00d9ff;
}
.txt {
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: width 0.1s ease;
}
.txt_active {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 10vw;
  height: 100%;
  padding: 10px 0px 10px 30px;
  font: 400 1.2rem "Daoli";
  color: #fff;
  /* overflow-y: scroll; */
}
.operationWithMe {
  background-image: url("../../assets/合作.svg");
  background-size: 100% 100%;
  object-fit: contain;
}
.joinus {
  background-image: url("../../assets/加入我们.svg");
  background-size: 100% 100%;
  object-fit: contain;
}
.callme {
  background-image: url("../../assets/联系我们.svg");
  background-size: 100% 100%;
  object-fit: contain;
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.8rem;
  font-size: 30px;
  font-weight: 900;
  font-family: Georgia, "Times New Roman", Times, serif;
  color: #ffffff;
  width: 8vw;
  writing-mode: vertical-rl;
}
.footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 25%;
  background-color: #fff;
}
.footer_body {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #e8e8e8;
  /* filter: contrast(10); */
}
.bubble {
  /* filter: blur(7px); */
  position: absolute;
  border-radius: 50px;
  background-color: #cecece;
  animation: bubbleMove calc(120s / var(--i)) linear infinite;
}
@keyframes bubbleMove {
  0% {
    transform: translateY(50vh) scale(0);
  }
  100% {
    transform: translateY(-5vh) scale(1);
  }
}
.InternetContentProvider {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 30%;
  flex-direction: column;
  font-size: 1rem;
}
.copyright {
  width: 100%;
  margin-bottom: 5px;
}
@media (max-width: 1350px) {
  .header_name {
    font-size: 38px;
  }
  .header_name::after {
    content: "庆快校园";
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 38px;
    color: #000000;
    z-index: -1;
    transform: translate(-15px, 7px) scaleY(0.5) skew(50deg);
    filter: blur(1px);
    mask: linear-gradient(transparent, #000);
  }
  .op {
    font-size: 1.4rem;
  }
  .title {
    font-size: 30px;
  }
  .txt_active {
    font-size: 1.15rem;
  }
  .InternetContentProvider {
    font-size: 0.9rem;
  }
}
@media (max-width: 900px) {
  .header_name {
    font-size: 33px;
  }
  .header_name::after {
    content: "庆快校园";
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 33px;
    color: #000000;
    z-index: -1;
    transform: translate(-15px, 7px) scaleY(0.5) skew(50deg);
    filter: blur(1px);
    mask: linear-gradient(transparent, #000);
  }
  .op {
    font-size: 1.2rem;
  }
  .title {
    font-size: 20px;
  }
  .txt_active {
    font-size: 0.9rem;
  }
  .InternetContentProvider {
    font-size: 0.8rem;
  }
}
@media (max-width: 500px) {
  .header {
    padding: 0px 10px;
  }
  .bg_txt {
    position: fixed;
    top: 11vh;
    right: 10px;
    font-size: 0.9rem;
  }
  .txt_active {
    padding: 1px 1px;
    font-size: 0.6rem;
  }
  .title {
    font-size: 10px;
  }
  .login_page_txt {
    width: 300px;
    height: 300px;
  }
}
::-webkit-scrollbar {
  display: none;
}
</style>
