// 路由配置
import Vue from 'vue'
import VueRouter from 'vue-router'
import FeegBack from '@/components/FeegBack/FeegBack.vue'
import PageRight from '@/components/HomePage/PageRight/PageRight.vue'
import AddAdmin from '@/components/AddAdmin/AddAdmin.vue' 
import StoreLocator from '@/components/StoreLocator/StoreLocator.vue'
import Login from '@/components/Login/Login.vue'
import Classify from '@/components/Classify/Classify.vue'
import search from '@/components/search/search.vue'
import worker from '@/components/Creatework/Creatework.vue'
import ava from '@/components/ava/ava.vue'
import avacla from '@/components/avacla/avacla.vue'
import icDesignSpace from '@/components/icDesignSpace/icDesignSpace.vue'
import icSpaceCategory from '@/components/icSpaceCategory/icSpaceCategory.vue'
import note from '@/components/note/note.vue'
import homeAd from '@/components/homeAd/homeAd.vue'
import HomePage from '@/components/HomePage/HomePage.vue'
import toolCabinet from '@/components/toolCabinet/toolCabinet.vue'
import M_comment from '@/components/M_comment/M_comment.vue'
import PointPack from '@/components/PointPack/PointPack.vue'
import storecomment from '@/components/storecomment/storecomment.vue'
import RecommedStore from '@/components/RecommedStore/RecommedStore.vue'


Vue.use(VueRouter)
const router =new VueRouter({
routes:[
  {path:'/',redirect:'Login'},
  {path:'/Login',component:Login},
  {path:'/search',component:search},
  {
    path:'/HomePage',
    component:HomePage,
    children:[
    {path:'PageRight',component:PageRight},
    {path:'feedback',component:FeegBack},
    {path:'AddAdmin',component:AddAdmin},
    {path: 'StoreLocator',component:StoreLocator},
    {path:'Classify',component:Classify},
    {path:'work',component:worker},
    {path:'ava',component:ava},
    {path:'avacla',component:avacla},
    {path:'icDesignSpace',component:icDesignSpace},
    {path:'icSpaceCategory',component:icSpaceCategory},
    {path:'M_comment',component:M_comment},
    {path:'note',component:note},
    {path:'homeAd',component:homeAd},
    {path:'toolCabinet',component:toolCabinet},
    {path:'PointPack',component:PointPack},
    {path:'storecomment',component:storecomment},
    {path:'RecommedStore',component:RecommedStore},
    { path: '', redirect: 'PageRight' }  
  ]},
 
]
})
// router.beforeEach((to, from, next) => {
//    next();
// })
export default router