<template>
  <div class="RecommedStore">RecommedStore</div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  components: {},
  data() {
    return {
      token: "",
    };
  },
  created() {},
  computed: {},
  methods: {
    getRecommend() {},
  },
  watch: {},
});
</script>

<style scoped></style>
