<template>
  <el-container class="toolCabinet">
    <el-row class="row" :gutter="12">
      <el-col class="col" v-for="(item, index) in toolList" :key="index">
        <el-main style="font-family: ShuHei; font-size: 1.6rem">
          {{ item.title }}
        </el-main>
        <el-row class="row1" :gutter="20">
          <el-col :span="5" v-for="(item1, index1) in item.item" :key="index1">
            <el-card :body-style="cardstyle" class="al" shadow="always">
              <div class="card flrow" @click="userTool(item1.id)">
                <div class="cardIcon flex">
                  <img :src="item1.iconurl" alt="" />
                </div>
                <div class="cardTxt flex">{{ item1.name }}</div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-dialog
      title="确定要清除缓存吗"
      :visible.sync="del_dis"
      width="25%"
      :append-to-body="true"
      center
    >
      <div
        style="
          display: flex;
          justify-content: center;
          font-size: 1.5rem;
          font-family: ShuHei;
        "
      >
        该操作不可逆，请谨慎考虑
      </div>
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 30px;
        "
      >
        <el-button type="primary" @click="clear()"> 确定清除 </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="获取图片CDN链接"
      :visible.sync="img_dis"
      width="50%"
      :append-to-body="true"
      :close-on-click-modal="false"
      center
    >
      <div class="js" style="width: 100%">
        <el-upload
          class="upload-demo"
          drag
          action="#"
          :on-change="getCDN"
          multiple
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text" style="width: 100%">
            将文件拖到此处，或<em>点击上传</em>
          </div>
          <div class="el-upload__tip" style="width: 100%" slot="tip">
            只能上传jpg/png文件, 且不超过500kb
          </div>
        </el-upload>
      </div>
      <div class="js" style="display: flex; width: 100%; margin-top: 30px">
        <el-input
          id="image_CDN_Path"
          style="
            display: flex;
            width: 90%;
            height: 100px;
            font-size: 1.1rem;
            font-weight: bold;
          "
          type="textarea"
          placeholder="此处获取图片链接"
          v-model="imageCdnPath"
        >
        </el-input>
      </div>
      <div style="width: 100%; padding-left: 5%">
        <el-button plain type="primary" size="small" @click="copyCDN()">
          一键复制链接
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="tailor_dis"
      width="75%"
      :append-to-body="true"
      :close-on-click-modal="false"
      center
    >
      <el-header class="tailotImg_title flex">裁剪图片</el-header>
      <el-main class="flrow">
        <el-row style="width: 100%" :gutter="20">
          <el-col :span="12">
            <el-main class="ImageOp flex">
              <el-header class="flex">裁剪区域</el-header>
              <vueCropper
                style="width: 20vw; height: 30vh"
                ref="tailorImg"
                :img="option.img"
                :outputSize="option.size"
                :outputType="option.outputType"
                :info="true"
                :full="option.full"
                :canMove="option.canMove"
                :canMoveBox="option.canMoveBox"
                :original="option.original"
                :autoCrop="option.autoCrop"
                :fixed="option.fixed"
                :fixedNumber="option.fixedNumber"
                :centerBox="option.centerBox"
                :infoTrue="option.infoTrue"
                :fixedBox="option.fixedBox"
                :autoCropWidth="option.autoCropWidth"
                :autoCropHeight="option.autoCropHeight"
                maxImgSize="400*400"
                @cropMoving="MoveCrop"
              />
              <el-upload
                style="margin-top: 10px"
                action="1"
                :auto-upload="false"
                :on-change="gettailor"
                multiple
                :file-list="fileList"
                :limit="2"
              >
                <el-button type="primary">点击上传</el-button>
              </el-upload>
            </el-main>
          </el-col>
          <el-col :span="12">
            <el-main class="ImgPreview flex">
              <el-header class="flex">预览图片</el-header>
              <img class="priviewImg" :src="previewImg" v-if="isPreview" />
            </el-main>
          </el-col>
        </el-row>
      </el-main>
      <el-footer style="margin-top: 30px; text-align: center">
        <el-button type="primary" plain @click="gettailorImgData()">
          裁剪图片
        </el-button>
      </el-footer>
    </el-dialog>
  </el-container>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import Clipboard from "clipboard";
import _ from "lodash";
export default Vue.extend({
  components: {},
  data() {
    return {
      token: "",
      del_dis: false,
      img_dis: false,
      imageCdnPath: "",
      toolList: [
        {
          title: "常用工具",
          item: [
            {
              id: 1,
              name: "清除缓存",
              iconurl: require("../../assets/清除缓存.svg"),
            },
            {
              id: 2,
              name: "图片压缩网站",
              iconurl: require("../../assets/压缩.svg"),
            },
            {
              id: 3,
              name: "图片CDN链接",
              iconurl: require("../../assets/图片.svg"),
            },
            {
              id: 4,
              name: "图片裁剪",
              iconurl: require("../../assets/裁剪.svg"),
            },
          ],
        },
      ],

      cardstyle: {
        width: "100%",
        padding: "10px 10px",
      },
      fileList: [],
      tailor_dis: false,
      isPreview: true,
      dialogVisible: false,
      previewImg: "", // 预览图片地址
      // 裁剪组件的基础配置option
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: "png", // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        canMoveBox: true, // 截图框能否拖动
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: false, // 是否输出原图比例的截图
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      // 防止重复提交
      loading: false,
    };
  },
  created() {
    this.token = localStorage.getItem("token");
  },
  mounted() {},
  computed: {},
  methods: {
    userTool(index) {
      switch (index) {
        case 1:
          this.del_dis = true;
          break;
        case 2:
          this.gotoWebsite();
          break;
        case 3:
          this.img_dis = true;
          break;
        case 4:
          this.show_tailor();
          break;
        default:
      }
    },

    clear() {
      axios
        .post("/api/admin/common/clearHomeImageCache", null, {
          headers: {
            "Content-Type": "application/json",
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: res.data.data,
              type: "success",
            });
          } else {
            this.$message.error("清除失败");
          }
        });
    },
    gotoWebsite() {
      window.location.href = "https://www.imgdiet.com/zh-CN/jpg-compress";
    },
    async getCDN(event) {
      const file = event.raw;
      let img = await this.Fileimage(file);
      this.imageCdnPath = img.data;
    },
    async Fileimage(file) {
      const FormData = require("form-data");
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`, // 边界是自动设置的，但为了某些特殊情况，您可以手动设置它
          token: this.token, // 假设 this.token 是您的认证令牌
        },
      };
      const res = await axios.post(
        "/api/admin/common/upload",
        formData,
        config
      );
      return res.data;
    },
    async copyCDN() {
      try {
        await navigator.clipboard.writeText(this.imageCdnPath);
        this.$message({
          type: "success",
          message: "复制成功",
        });
      } catch (err) {
        try {
          let element = document.querySelector("#image_CDN_Path");
          element.select();
          document.execCommand("copy");
          this.$message({
            type: "success",
            message: "复制成功",
          });
        } catch (err) {
          this.$message({
            type: "error",
            message: "复制失败，请手动复制！",
          });
        }
      }
    },

    show_tailor() {
      this.tailor_dis = true;
    },
    //上传图片
    gettailor(e) {
      this.fileList = [];
      this.fileBase64(e.raw)
        .then((res) => {
          this.option.img = res;
          this.$refs.tailorImg.getCropData((data) => {
            this.previewImg = data;
          });
        })
        .catch(() => {
          this.message.error("图片上传失败");
        });
    },
    //图片转base64
    fileBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(reader.error);
      });
    },
    //边框移动
    MoveCrop(e) {
      this.$refs.tailorImg.getCropData((data) => {
        // do something
        this.previewImg = data;
      });
    },
    //得到裁剪后图片
    gettailorImgData() {
      this.$refs.tailorImg.getCropBlob((blob) => {
        // 创建下载链接
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        const fileheader = "quick_pickup";
        const time = Date.now();
        const footerNum = _.random(1000, 9999);
        link.download = fileheader + time + footerNum + ".png"; // 你可以根据需要更改文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
  },
  watch: {},
});
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.al {
  display: flex;
  align-items: center;
}
.js {
  display: flex;
  justify-content: center;
}
.flrow {
  display: flex;
  flex-direction: row;
}
.toolCabinet {
  width: 100%;
  height: 100%;
}
.row {
  width: 100%;
  height: 100%;
}
.col {
  width: 100%;
}
.row1 .el-col {
  margin-top: 20px;
}
.row1 .el-col .el-card {
  height: 70px;
  border-radius: 5px;
}
.row1 .el-col .el-card:hover {
  transform: translateY(-5px);
  transition: transform 0.3s ease;
}
.card {
  width: 100%;
  height: 100%;
}
.cardIcon {
  width: 30%;
}
.cardTxt {
  flex: 1;
  font-family: "ShuHei";
  font-size: 1.1rem;
  /* background-color: aquamarine; */
}
.cardIcon img {
  width: 3rem;
  height: 3rem;
}
.tailorImg_bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.3);
}
.tailorImg {
  width: 70%;
  height: 80%;
  background-color: #fff;
}
.tailotImg_title {
  width: 100%;
  height: 50px;
  font-family: "Daoli";
  font-size: 1.4rem;
}
.ImageOp {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.loadingInput {
  background-color: #00aeff;
}
.ImgPreview {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.priviewImg {
  width: 20vw;
  height: 30vh;
  object-fit: contain;
}
@media (max-width: 900px) {
  .cardIcon img {
    width: 1.7rem;
    height: 1.7rem;
  }
  .cardTxt {
    font-size: 0.9rem;
  }
}
</style>
